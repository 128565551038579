




































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BCard, BCardBody, BCardSubTitle, BCardTitle, BFormCheckbox, BTable, BButton} from "bootstrap-vue";
import User = EmployeeAPI.User.User;

@Component({
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BButton
  }
})
export default class UserDataCard extends Vue {
  @Prop({
    default: () => {
      return []
    }, required: true
  })
  items: any

  @Prop({
    default: () => {
      return []
    }, required: true
  })
  title: string
}

