





















































import {Vue, Component, Prop} from 'vue-property-decorator'
import User = CandidateAPI.Candidate.User;
import {
  BRow, BCol, BAlert, BLink, BTable
} from 'bootstrap-vue'
import UserInfoCard from "@/apps/candidate-api/pages/Users/single/components/user-info-card.vue";
import UserDataCard from "@/apps/candidate-api/pages/Users/single/components/user-data-card.vue";
import UserDataRegistrationsCard from "@/apps/candidate-api/pages/Users/single/components/user-data-registrations-card.vue";

@Component({
  components: {UserDataCard, UserDataRegistrationsCard, UserInfoCard, BRow, BCol, BAlert, BLink, BTable}
})
export default class CandidateUser extends Vue {
  item?: User = null;
  includes: string[] = [
    "emails",
    "phonenumbers",
    "addresses",
    "educationhistories",
    "workhistories",
    "licences",
    "languages",
    "skills",
    "curriculumvitaes",
    "bankaccount",
    "fields{UserInformation:PersonalStatement;UserInformation:CoverLetter}",
    "lookingfor",
    "searchhistories",
    "trackedapplications",
    "policyacceptance",
    "qualifications",
    "registrations"
  ];

  get id() {
    return this.$route.params.identifier;
  }

  async mounted() {
    await this.getItem();
  }

  async getItem() {
    const user = await this.$api.candidate.user.Get(this.id, this.includes);
    this.$set(this, "item", user);
  }
}
