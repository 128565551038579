










































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BCard, BCardBody, BCardSubTitle, BCardTitle, BFormCheckbox, BTable, BButton} from "bootstrap-vue";
import User = EmployeeAPI.User.User;
import Registration = CandidateAPI.Registration;

@Component({
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BButton
  }
})
export default class UserDataRegistrationsCard extends Vue {
  @Prop({
    default: () => {
      return []
    }, required: true
  })
  items: any

  get formattedItems() {
    return this.items.map(item => {
      return {
        ...item,
        actions: ""
      }
    })
  }

  @Prop({
    default: () => {
      return []
    }, required: true
  })
  title: string


  async reindex(data: Registration) {
    console.log(data)
    await this.$api.registrations.maintenance.changeStatus(data.id, data.status);
  }
}

