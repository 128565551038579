









































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {BAvatar, BButton, BCard, BCol, BRow} from "bootstrap-vue";
import User = CandidateAPI.Candidate.User;
import {avatarText} from '@/helpers/filters/avatarText';
import {isEqual} from "lodash";
import {NotifySuccess} from "@/helpers/Toast";

@Component({
  components: {BCard, BButton, BRow, BCol, BAvatar}
})
export default class UserInfoCard extends Vue {
  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item?: User;

  get fullName() {
    return `${this.item.firstName} ${this.item.lastName}`
  }

  avatarText() {
    return avatarText(this.fullName)
  }

  gdprModelApproved = false
  async requestGDPRRemoval() {
    this.$bvModal
        .msgBoxConfirm('Please confirm that you want to request a GDPR removal.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          this.gdprModelApproved = value
        })
  }

  async reindexRegistrations() {
    const req = await this.$api.registrations.maintenance.reindexByCandidateId(this.item.id);
    if(req != null) {
      NotifySuccess(["Reindex Successfully Completed"])
    }
  }

  @Watch('gdprModelApproved')
  async watchGdprModelApproved(newValue) {
    if(newValue){
      const req = await this.$api.maintenance.gdprrequests.Create({
        userId: this.item.id,
        type: this.$route.params.platform
      });

      if(req != null) {
        NotifySuccess(["GDPR Request Successfully Created"])
      }
    }else{
      console.log("not approved")
    }
  }
}
